import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "@fortawesome/fontawesome-free/css/all.min.css";

import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './styles.css';

import Header from './components/header'
import Content from './components/content'
import Footer from './components/footer'

function App() {
  return (
    <>
      <Header />
      <Content />
      <Footer />
      <div id="alert" className="position-fixed bottom-0 end-0 alert text-center me-4"></div>
    </>
  );
}

const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);