const Footer = () => {
    return(
        <>
            <footer id="footer" className="bg-secondary">
            <div  className="container-md d-flex flex-column flex-md-row justify-content-between align-items-start px-5 px-md-0">
            <div className="d-flex flex-column flex-md-row align-items-start col-12 col-md-4 py-5">
                <img src="images/assets/logo-white.png" className="col-12 col-md-3 mb-4" alt="footer-logo" />
                <div className="d-flex flex-column col-12 col-md-9 px-md-5">
                <h2 className="text-white">FOLAN LOGISTICS LLC</h2>
                <p className="mb-4">We are a California licensed auto dealership establishment, with experience in shipping and Logistics, offering, auto procurement & logistics services from any location in the United States to any port of your choice around the world.</p>
                <div className="d-inline-flex mb-4">
                    <i className="fa-brands fa-facebook fs-2 pe-4"></i>
                    <i className="fa-brands fa-twitter fs-2 pe-4"></i>
                    <i className="fa-brands fa-linkedin fs-2 pe-4"></i>
                    <i className="fa-brands fa-whatsapp fs-2"></i>
                </div>
                </div>
            </div>
            <div className="d-block col-12 col-md-2 py-5">
                <div className="quicklinks">
                <h2 className="text-reset">Quick Links</h2>
                <ul>
                    <li><a href="/">Homepage</a></li>
                    <li><a href="#about">About Us</a></li>
                    <li><a href="#services">Our Services</a></li>
                    <li><a href="/#">FAQ</a></li>
                    <li><a href="/news">Industry News</a></li>
                    <li><a href="#contact">Contact Us</a></li>
                </ul>
                </div>
            </div>
            <div className="d-block col-12 col-md-2 py-5">
                <div className="servicelinks">
                <h2 className="text-reset">Our Services</h2>
                <ul>
                    <li><a href="#services">Automobiles</a></li>
                    <li><a href="#services">Personal Effects</a></li>
                    <li><a href="#services">Marine Cargo</a></li>
                    <li><a href="#services">Container Shipping</a></li>
                    <li><a href="#services">Truck & Freight</a></li>
                    <li><a href="#services">RORO Shpping</a></li>
                </ul>
                </div>
            </div>
            <div className="col-12 col-md-4 py-5">
                <h2 className="text-white">Feeds</h2>
                <p className="mb-4"><i className="fa-brands fa-twitter pe-2"></i>Twitter feed goes here</p>
                <h2 className="text-white">Newsletter</h2>
                <small>Subscribe to our newsletter</small>
                <div className="d-inline-flex">
                <input type="email" name="email" className="border-0 rounded-end rounded-pill p-3 fst-italic" placeholder="email" />
                <button type="button" className="btn btn-primary rounded-start rounded-pill py-2 px-4 fs-4" disabled>Subscribe <i className="fa-regular fa-paper-plane"></i></button>
                </div>
            </div>
            </div>
        </footer>
        <div className="copyright bg-secondary py-2 border-top border-tertiary">
            <div className="container-md mx-auto d-flex flex-column flex-md-row justify-content-between align-items-center text-white text-center px-5 px-md-0">
            <small>Copyright &copy; 2023 Folan Logistics LLC- All Rights Reserved</small>
            <small>Design & Developed By <a href="https://www.reglerservices.com" target="_blank" rel="noreferrer author">Regler Services</a></small>
            </div>
        </div>
        </>
    )
}

export default Footer;