const Header = () => {
    return (
        <nav className="navbar sticky-top navbar-expand-lg bg-primary border-top border-tertiary border-5 shadow">
        <div className="container-md mx-auto p-3 p-lg-0">
          <a className="navbar-brand text-white" href="/">
            <img src="images/assets/logo-white.png" className="w-50" alt="Folan Logistics LLC"></img>
          </a>
          <i className="fa-solid fa-bars text-white fs-3 d-lg-none" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation"></i>
          <div className="collapse navbar-collapse justify-content-between" id="navbar">
            <ul className="navbar-nav text-uppercase fw-bolder fs-5" style={{"--bs-nav-link-color": "#FFFFFF"}}>
              <li className="nav-item">
                <a className="nav-link" href="/#">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#about">About Folan LLC</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#services">Services</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#partners">Our Partners</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">Contact Us</a>
              </li>
            </ul>
            <div className="d-flex text-white flex-column flex-md-row px-4">
              <div>
                <i className="fa-solid fa-envelope-circle-check fs-3 pe-2"></i>
                <span className="pe-5">
                    info@folanlogistics.com
                </span>
              </div>
              <div>
                <i className="fa-solid fa-square-phone-flip fs-3 pe-2"></i>
                <span>
                    +1 310-995-4470 <br />
                </span>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
}

export default Header;