import { useState } from 'react'

import Map from './googlemap'

const defaultValues = {
    name: "",
    email: "",
    tel: "",
    subject: "",
    addifo: ""
}

const Content = () => {
    const [contact, setContact] = useState(defaultValues)
    const alert = document.getElementById("alert")

    const handleSubmit = (e) => {
        e.preventDefault()
        alert.classList.toggle("alert-success")
        alert.innerHTML = `Thank you ${contact.name.split(" ")[contact.name.split(" ").length - 1]}, your query has been successfully submitted`
        setContact(defaultValues)
        setTimeout(() => {
            alert.classList.toggle("alert-success")
            alert.innerHTML = ""
        }, 10000)
    }

    return (
        <>
            <div id="carousel" className="carousel slide" data-bs-ride="false">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                </div>
                <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src="images/car-carrier-transport.jpeg" className="d-block w-100" alt="car-carrier-transport" />
                    <div className="carousel-caption d-none d-md-block">
                    <h2 className="fs-big text-white">FOLAN LOGISTICS LLC</h2>
                    <h5 className="text-white">Everything Automotive & Logistics</h5>
                    </div>
                </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carousel" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carousel" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
                </button>
            </div>
            <section id="about" className="container-md text-center center position-relative">
                <img src="/images/dotted-world-map-bg.svg" className="position-absolute top-50 start-50 translate-middle opacity-25 mw-100" alt="world-map" />
                <img src="images/assets/logo.png" alt="folan-logistics-llc-logo" className="mb-4" />
                <header className="d-none">Everything<br /> Automotive & Logistics</header>
                <p className="opacity-75 mb-5"><strong>FOLAN LOGISTICS LLC</strong> is a California licensed auto dealership establishment, with experience in shipping and Logistics. We offer, auto procurement services, International shipping of vehicles, autos, trucks, buses, motorcycles, boats, high and heavy machinery, trailers, commodities and containers from any location in the United States to any port of your choice around the world. We can pick up your vehicle or container at your door and ship it to your consignee worldwide. We raise the standard of excellence in the transportation logistics services industry by providing a one-stop solution to our clients for all their transportation needs.</p>
                <div className="d-flex flex-column flex-md-row">
                    <div className="fs-4 position-relative bg-primary bg-opacity-25 m-2 border-5 border-start">
                        <h1 className="fs-extrabig position-absolute top-0 start-0 opacity-25 p-4">Our Mission</h1>
                        <p className="p-5">To be the premier Logistics company that seamlessly connects businesses and individuals across the globe, fostering prosperity and growth through professional, reliable, efficient and sustainable logistics solutions using our innovative technologies and unwavering dedication to our customer satisfaction.</p>
                    </div>
                    <div className=" text-white fs-4 position-relative bg-danger bg-opacity-100 m-2 border-5 border-end">
                        <h1 className="fs-extrabig position-absolute top-0 end-0 opacity-25 text-reset p-4">Our Vision</h1>
                        <p className="p-5">To be the leading force to individuals and businesses in the logistics industry and the world at large through operational excellence, global connectivity, innovative solutions and customer empowerment.</p>
                    </div>
                </div>
            </section>
            <section id="partners" className="partners text-center p-0 bg-white">
                <div className="d-flex flex-wrap justify-content-center py-5">
                <img src="images/assets/bhl-logo.png" alt="bhl-logo" />
                </div>
            </section>
            <section id="cta" className="text-center py-5" style={{background: "rgba(240, 240, 240, 0.5) url(/images/containers-faed.jpg)"}}>
                <div className="container-md py-5">
                <h1 className="" style={{fontSize: "3rem"}}>Looking For A<br /><span className="text-danger">Reliable</span> Logistics Transport Service?</h1>
                <p className="mb-4"><small>Look No Further <i className="fa-solid fa-caret-right"></i> We specialize in the following shipping and related services</small></p>
                <div className="d-flex flex-column flex-md-row gap-4 justify-content-center mb-4">
                    <a href="#contact" type="button" className="btn btn-secondary servicebutton rounded-pill fs-3 fw-bold">Contact<i className="fa-solid fa-envelope-open ps-3"></i></a>
                    <a href="#services"  type="button" className="btn btn-primary servicebutton rounded-pill fs-3 fw-bold">Explore Our Services <i className="fa-solid fa-circle-arrow-down ps-5"></i></a>
                </div>
                </div>
            </section>
            <section id="services" className="row row-cols-3 text-center align-items-center p-0">
                <div className="position-relative col-12 col-md-4 services adjacent">
                <img src="/images/automobiles-2.jpeg" className="" alt="automobile" />
                <div className="content bg-primary">
                    <h2 className="text-end text-white fs-extrabig position-absolute bottom-0 end-0 opacity-25 px-5">Automobile</h2>
                    <p className="z-1">We provide and handle all your general automobile needs which includes importation, purchase and sales of cars and vehicles, top quality spare parts in good condition and repairs. </p>
                </div>
                </div>
                <div className="position-relative col-12 col-md-4 services">
                <img src="/images/personal-effects-1.jpg" className="" alt="personal-effects" />
                <div className="content bg-secondary">
                    <h2 className="text-start text-white fs-extrabig position-absolute top-0 start-0 opacity-25 px-5">Personal<br />Effect </h2>
                    <p className="z-1">Folan Logistics offers efficient solution and on budget means of shipping your personal effects and belongings from your door step or our warehouse to any location in the world.</p>
                </div>
                </div>
                <div className="position-relative col-12 col-md-4 services adjacent">
                <img src="/images/marine-cargo-insurance-3.jpg" className=""  alt="marine-insurance" />
                <div className="content bg-tertiary">
                    <h2 className="text-danger text-center fs-extrabig position-absolute bottom-0 start-25 opacity-75">Marine Cargo Insurance</h2>
                    <p className="fw-semibold">Folan Logistics offers marine cargo insurance to customers, to secure and protect valuable cargo.</p>
                </div>
                </div>
                <div className="position-relative col-12 col-md-4 services">
                <img src="/images/containers.jpeg" className="" alt="container" />
                <div className="content bg-secondary bg-gradient">
                    <h1 className="text-start text-white fs-extrabig position-absolute top-0 start-0 opacity-25 px-5">Container<br />Shipping</h1>
                    <p className="z-1">Folan Logistics deploys innovative technique for vehicle safety in full loaded container (FCL) with construction of wooden lamps in 40-feet standard and high cube container.</p>
                </div>
                </div>
                <div className="position-relative col-12 col-md-4 services adjacent">
                <img src="/images/trucking.jpeg" className="" alt="freight-truck" />
                <div className="content bg-dark">
                    <h1 className="text-white fs-extrabig opacity-100 shadow-lg text-start position-absolute top-50 start-50 translate-middle">Truck Freighting</h1>
                    <p className="z-1"></p>
                </div>
                </div>
                <div className="col-12 col-md-4 services position-relative">
                <img src="/images/car-carrier-transport-2.jpeg" className="" alt="roro" />
                <div className="content bg-primary bg-gradient">
                    <h2 className="text-end text-white fs-extrabig position-absolute bottom-0 end-0 opacity-25 px-5">RORO <br />Shipping</h2>
                    <p className="z-1">We also offer roll-on roll-off shipping services which also includes high and heavy machinery that can be an over sized or an over gauged cargo. </p>
                </div>
                </div>
            </section>
            <section id="contact" className="position-relative p-0" style={{height: "600px"}}>
                <Map />
                <div className="container-md center d-flex flex-column flex-md-row px-5 align-items-center position-absolute top-50 start-50 translate-middle">
                    <form className="col-12 col-md-5 order-2 px-md-5 ps-md-2 justify-content-between mb-5" onSubmit={handleSubmit}>
                        <input type="text" name="name" className="p-3 w-100 mb-2" value={contact.name} onChange={(e) => setContact({...contact, name: e.target.value})} placeholder="Your Fullname" required/>
                        <div className="row justify-content-between">
                            <input type="email" name="email" className="w-100 p-3 mb-2" value={contact.email} onChange={(e) => setContact({...contact, email: e.target.value})} placeholder="E-mail Address" required/>
                            <input type="tel" name="tel" className="w-100 p-3 mb-2" value={contact.tel} onChange={(e) => setContact({...contact, tel: e.target.value})} placeholder="Phone Number" required/>
                        </div>
                        <input type="text" name="subject" className="p-3 w-100 mb-2" value={contact.subject} onChange={(e) => setContact({...contact, subject: e.target.value})} placeholder="Message Subject" required/>
                        <div className="d-flex flex-column">
                            <textarea name="addinfo" className="p-4 mb-2" rows={6} value={contact.addinfo} onChange={(e) => setContact({...contact, addinfo: e.target.value})} style={{resize: "none"}} placeholder='Additional Information'/>
                            <input type="submit" className="btn btn-primary p-4 fs-4" value="Send"/>
                        </div>
                    </form>
                    <div className="col-12 col-md-4 order-1 mb-5">
                        <div className="d-block justify-content-center text-center text-md-end px-5 py-2">
                        <h1 className="fs-extrabig">Have<br/>An<br/>Enquiry?</h1>
                        <p className="mb-4">Fill out and submit this form and we will do well to get back to you. You can also locate us using our addresses below. Thank you!</p>
                        <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-end mb-4">
                            <i className="fa-solid fa-map-location-dot fs-big pe-2 text-primary"></i>
                            <p className="text-center text-md-start">
                                1704 Manchester Avenue <br />
                                Suite 101C, LA, CA, 90047 <br/>
                                USA
                            </p>
                        </div>
                        <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-end mb-4">
                            <i className="fa-solid fa-square-phone-flip fs-big pe-2 text-primary"></i>
                            <p className="text-center text-md-start">
                                +1 310-995-4470 <br />
                                +1 310-491-4771 <br />
                                +1 310-920-7282
                            </p>
                        </div>
                        <div className="d-flex flex-column flex-md-row  justify-content-center justify-content-md-end ">
                            <i className="fa-solid fa-envelope-circle-check fs-3 pe-2 text-primary"></i>
                            <p>
                                info@folanlogistics.com
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Content;