import GoogleMapReact from 'google-map-react';

const ReactComponent = ({ text }) => (
  <div>
    <i className={text}></i>
  </div>
);

const Map = () => {
    const defaultProps = {
    center: {
      lat: 33.95,
      lng: -118.30
    },
    zoom: 10
  };

  return (
    <div style={{ height: '100%', width: '100vw', opacity: .3 }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAkFtokqvx17fwQWfsqSQz7nP-fBys0IMA" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <ReactComponent
            lat = {33.959648}
            lng = {-118.307060}
            text = "fa-solid fa-location-dot fs-extrabig text-danger"
        />
      </GoogleMapReact>
    </div>
  );
}

export default Map;